<template>
    <TaskPageWrapper :task="task" :instructions="taskPageInstructions" @update="loadTask"
                     @approve="toggleTaskApprovalModal" @reject="toggleTaskRejectingModal">
        <TaskPageDocumentFileArea v-if="isPendingSubmission" title="Document" color="blue" :upload="true"
                                  :loading="loading" @submit="uploadTaskDocument"/>

        <TaskPageDocumentFileArea v-if="isPendingApproval || isCompleted" title="Document" color="green"
                                  icon="check-green" :document-name="task.file_name"
                                  :can-delete="isTaskSubContractor && isPendingApproval"
                                  @linkClicked="toggleDocumentPreview(task.latest_upload.extension, 'preview-uploaded-file')"
                                  @delete="confirmTaskUploadDelete('uploaded document', () => loadTask())">
            <LoadingButton color="primary" variant="outline" :loading="loadingTaskFileDownload"
                           @click="downloadTaskUploadedFile">
                Download
            </LoadingButton>
            <CButton v-if="isTaskGeneralContractor && isPendingApproval" class="d-block" color="primary"
                     @click="toggleTaskRejectingModal">
                Reject & Send Message
            </CButton>
            <LoadingButton v-if="isCompleted && isTaskGeneralContractor" :loading="loading" :aria-disabled="loading"
                           color="danger" variant="outline"
                           @click="confirmTaskUploadDelete('uploaded document', () => loadTask())">
                Delete Uploaded Document
            </LoadingButton>
        </TaskPageDocumentFileArea>

        <TaskPageDocumentFileArea title="Sample" color="blue" icon="sample" :document-name="task.file_name"
                                  @linkClicked="toggleDocumentPreview(getFileExtension(task.file_name))"/>

        <div v-if="taskRejectedUploads.length > 0" class="mt-4">
            <TaskPageRejectedDocuments :task-id="task.id" :task-file-name="task.file_name"
                                       :task-rejected-uploads="taskRejectedUploads"/>
        </div>
    </TaskPageWrapper>
    <ConfirmDialog ref="confirmDialog"/>
    <AppModal content-class="reject-modal" :visible="taskApprovalModal"
              title="Are you sure you want to approve the file?" @close="toggleTaskApprovalModal">
        <template #footer>
            <CButton color="primary" variant="outline" @click="toggleTaskApprovalModal">Cancel</CButton>
            <CButton color="primary" @click="approveTaskDocument">Confirm</CButton>
        </template>
    </AppModal>
    <AppModal content-class="reject-modal" :visible="taskRejectingModal" title="Rejecting Document"
              text="Explaining why you reject this file will help your partner with providing the proper document"
              @close="toggleTaskRejectingModal">
        <p class="tip">Why do you reject this file?</p>
        <CNTextarea v-model="taskRejectingData.notes" :required="true" :invalid="!!validationErrors.notes"
                    @blur="validateField(taskRejectingData, taskRejectingRules, 'notes')"
                    class="form-control" label="Leave a comment here" :max-length="1000"/>
        <CFormText class="form-error">{{ validationErrors.notes }}</CFormText>
        <template #footer>
            <CButton color="primary" variant="outline" @click="toggleTaskRejectingModal">Cancel</CButton>
            <LoadingButton :loading="loading" color="danger" @click="rejectTaskDocument">Reject File</LoadingButton>
        </template>
    </AppModal>
    <DocumentPreviewer :visible="documentPreviewer.visible" type="tasks" :type-id="taskId" previewer="third-party"
                       :route="documentPreviewer.route" :document-name="task?.file_name"
                       :extension="documentPreviewer.extension" @closed="toggleDocumentPreview(null, null)"/>
</template>

<script>
import TaskPageWrapper from '@/components/TaskPages/TaskPageWrapper.vue';
import ConfirmDialog from '@/components/Modals/ConfirmDialog.vue';
import Tasks from "@/api/v2/endpoints/Tasks";
import taskHelper from "@/mixin/taskHelper";
import TaskPageDocumentFileArea from "@/components/TaskPages/TaskPageDocumentFileArea.vue";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import AppModal from "@/components/Modals/AppModal.vue";
import CNTextarea from "@/components/ui/CNTextarea/CNTextarea.vue";
import rules from "@/utils/validator/rules";
import TaskPageRejectedDocuments from "@/components/TaskPages/TaskPageRejectedDocuments.vue";
import fileExtension from "@/mixin/fileExtension";

export default {
    components: {
        TaskPageRejectedDocuments,
        CNTextarea, AppModal,
        LoadingButton,
        DocumentPreviewer,
        TaskPageDocumentFileArea,
        ConfirmDialog,
        TaskPageWrapper,
    },
    mixins: [taskHelper, apiErrorHandler, fileExtension],
    data() {
        return {
            loading: false,
            taskApprovalModal: false,
            taskRejectingModal: false,
            taskRejectingData: {
                notes: null,
            },
            taskRejectingRules: {
                notes: [rules.required, rules.strMax(1000)],
            },
            subTaskPageInstructions: [
                'You can download and use the attached sample.',
                'Or you can upload your own document.'
            ],
            gcTaskPageInstructions: [
                'Approve or reject the provided document.',
                'If a document is rejected, please provide an explanation to your partner.',
            ],
            documentPreviewer: {
                route: null,
                visible: false,
                extension: null,
            },
            taskId: null,
            task: null,
        };
    },
    computed: {
        taskRejectedUploads() {
            return this.task && this.task.uploads
                ? this.task.uploads.filter(upload => upload.task_approval?.is_approved === false)
                : [];
        },
    },
    created() {
        this.taskId = this.$route.params.task_id;
        this.loadTask();
    },
    methods: {
        loadTask() {
            Tasks
                .show(this.taskId, {
                    task_data: this.taskPageData.toString(),
                    with_external_company: 'id,name',
                    with_internal_company: 'id,name',
                    with_company_assigner_users: 'id,full_name',
                    with_company_signer_users: 'id,full_name',
                    with_task_approval: 'all',
                    with_project: 'name',
                    with_uploads: 'all',
                    with_latest_upload: 'all',
                    with_task_file_updates: 1
                })
                .then(response => this.task = response.data);
        },
        uploadTaskDocument(file) {
            this.toggleLoading();

            Tasks
                .upload(this.task.id, this.uploadFileData(file))
                .then(() => {
                    this.toast('info', 'Document successfully sent to review!');
                    this.loadTask();
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleLoading());
        },
        uploadFileData(file) {
            const data = new FormData();
            data.append('file', file);

            return data;
        },
        approveTaskDocument() {
            Tasks
                .approve(this.task.id)
                .then(() => {
                    this.toast('info', 'Task document has been successfully approved!');
                    this.loadTask();
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleTaskApprovalModal());
        },
        rejectTaskDocument() {
            this.toggleLoading();

            Tasks
                .reject(this.task.id, this.taskRejectingData)
                .then(() => {
                    this.toast('info', 'The document has been rejected');
                    this.loadTask();
                })
                .catch(response => this.handleApiError(response))
                .finally(() => {
                    this.toggleLoading();
                    this.toggleTaskRejectingModal();
                });
        },
        toggleDocumentPreview(extension, route = 'preview-file') {
            this.documentPreviewer.route = route;
            this.documentPreviewer.extension = extension;
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
        },
        toggleTaskApprovalModal() {
            this.taskApprovalModal = !this.taskApprovalModal;
        },
        toggleTaskRejectingModal() {
            this.taskRejectingModal = !this.taskRejectingModal;
        },
        toggleLoading() {
            this.loading = !this.loading;
        },
    },
};
</script>

<style scoped lang="scss">
.reject-modal {
    width: 688px;

    & .form-control {
        border: none;
        padding: 0;
    }

    .tip {
        font-size: 12px;
        font-weight: 600;
        color: rgba(103, 122, 137, 1);
    }

    & .common-modal__buttons {
        & .btn {
            min-width: 200px;
        }

        & .btn-danger {
            color: white;
        }
    }
}
</style>
